import { IDropDownItem } from ".";
import authentication from '../common/Authentication';
import { GlobalConfig } from '../GlobalConfig';
import { JoinUrl } from './JoinUrl';
import {notify} from "react-notify-toast";

export class ApiServiceBase {
    private static handleError(result: Response, method: string): Response {
        let message = '';

        switch (result.status) {
            case 401:  authentication.signOut();
                return new Response;
            case 403:
                message = "You do not have permissions to do that!";
                break;
            case 404:
                message = "Resource was not found";
                break;
            case 500:
                message = "Internal Server Error";
                break;
        }
        notify.show(message, "error");

        throw new Error(`Failed to ${method} data in API service. Response status is: ${result.status}`);
    }


    public get(actionPath: string, urlParams?: any): Promise<any> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const urlQuery = this.urlQueryString(urlParams);
        const url = JoinUrl(apiBaseUrl, actionPath, urlQuery, );

        return fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + authentication.getAccessToken(),
                }
            })
            .then ((result: any) => {
                if (result.ok) {
                    return result.json();
                }
                else{
                    return ApiServiceBase.handleError(result,'get');
                }
            });
    }

    public fetchRaw(actionPath: string, urlParams?: any): Promise<Response> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const urlQuery = this.urlQueryString(urlParams);
        const url = JoinUrl(apiBaseUrl, actionPath, urlQuery, );

        return fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + authentication.getAccessToken(),
                }
            })
            .then ((result: Response) => {
                if (result.ok) {
                    return result;
                }
                else{
                    return ApiServiceBase.handleError(result,'get');
                }
            });
    }

    public post(actionPath: string, urlParams?: any, manualErrorHandling: boolean = false): Promise<Response> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const urlQuery = this.urlQueryString(urlParams);
        const url = JoinUrl(apiBaseUrl, actionPath, urlQuery, );

        return fetch(url, {
                method: 'post',
                headers: {
                    'Authorization': 'Bearer '  + authentication.getAccessToken(),
                }
            })
            .then ((result: Response) => {
                if(result.ok || manualErrorHandling) {
                    return result;
                }
                else{
                  return ApiServiceBase.handleError(result, 'post');
                }
            });
    }

    public postData(actionPath: string, urlParams?: any, data?:any): Promise<any> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const urlQuery = this.urlQueryString(urlParams);
        const url = JoinUrl(apiBaseUrl, actionPath, urlQuery, );

        return fetch(url, {
                method: 'post',
                headers: {
                    'Authorization': 'Bearer '  + authentication.getAccessToken(),
                    'Content-Type': 'application/json'
                },
                body : JSON.stringify(data)
            })
            .then ((result: any) => {
                if (result.ok) {
                    return result.json().catch(() => {
                        return "";
                      });
                }
                else{
                    return ApiServiceBase.handleError(result, 'post');
                }
            });
    }

    public postRawData(actionPath:string, data?: any): Promise<any> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const url = JoinUrl(apiBaseUrl, actionPath);

        return fetch(url, {
            method: 'post',
            headers: {
                'Authorization': 'Bearer '  + authentication.getAccessToken(),
                'Content-Type': 'application/json'
            },
            body : JSON.stringify(data)
        })
            .then ((result: Response) => {
                if (result.ok) {
                    return result;
                }
                else{
                    return ApiServiceBase.handleError(result, 'post');
                }
            });
    }

    public putData(actionPath: string, urlParams?: any, data?:any): Promise<any> {
        const apiBaseUrl = GlobalConfig.getApiUrl();
        const urlQuery = this.urlQueryString(urlParams);
        const url = JoinUrl(apiBaseUrl, actionPath, urlQuery, );

        return fetch(url, {
                method: 'put',
                headers: {
                    'Authorization': 'Bearer '  + authentication.getAccessToken(),
                    'Content-Type': 'application/json'
                },
                body : JSON.stringify(data)
            })
            .then ((result: any) => {
                if (result.ok) {
                    return result.json().catch(() => {
                        return "";
                      });
                }
                else{
                    return ApiServiceBase.handleError(result, 'put');
                }
            });
    }

    public getDropDownItems(actionPath: string, defaultItem? : IDropDownItem): Promise<IDropDownItem[]> {
        return new Promise<IDropDownItem[]>((resolve) => {
            this.get(actionPath)
                .then((response) => {
                    const dropDownItems: IDropDownItem[] = response.map((item: any) => {
                        switch(typeof item) {
                            case "string": 
                                return this.getDropDownItemFromString(item);
                            case "object":
                                return this.getDropDownItemFromObject(item);
                            default:
                                return;
                        }
                    });

                    if (defaultItem) {
                        dropDownItems.splice(0, 0, defaultItem);
                    }
                    
                    resolve(dropDownItems);
                });
        });
    }
	
	public deleteData(actionPath: string, urlParams?: any, data?: any): Promise<any> {
		const apiBaseUrl = GlobalConfig.getApiUrl();
		const urlQuery = this.urlQueryString(urlParams);
		const url = JoinUrl(apiBaseUrl, actionPath, urlQuery);
		return fetch(url, {
		  method: "delete",
		  headers: {
			Authorization: "Bearer " + authentication.getAccessToken(),
			"Content-Type": "application/json",
		  },
		  body: JSON.stringify(data),
		}).then((result: any) => {
            if(result.ok) {
                return result;
            }
            else{
                return ApiServiceBase.handleError(result, 'delete');
            }
		});
	  }

    private urlQueryString(urlParams: any): string {
        let queryString = '';
        if (urlParams) {
            switch(typeof urlParams) {
                case "string":
                    queryString = encodeURIComponent(urlParams);
                    break;
                case "object":
                    queryString = this.objectToUrlQueryString(urlParams);
                    break;
            }
        } 

        if (queryString.length > 0 && !queryString.startsWith('?')) {
            queryString = '?' + queryString;
        }

        return queryString;
    }

    private objectToUrlQueryString(urlParams: any) : string {
        if (typeof urlParams !== "object") {
            return '';
        }

        let queryString = '';
        Object.keys(urlParams).forEach((key, index) => {
            if (urlParams[key] && urlParams[key].toString().length > 0) {
                if(Array.isArray(urlParams[key])){
                    urlParams[key].forEach((element: any) =>{
                        queryString += `${key}=${encodeURIComponent(element)}&`
                    });
                }else{
                    queryString += `${key}=${encodeURIComponent(urlParams[key].toString())}&`
                }
            }
        });

        if (queryString.length > 0) {
            if (queryString.endsWith('&')) {
                queryString = queryString.substr(0, queryString.length -1);
            }
            return '?' + queryString;
        }

        return '';
    }

    private getDropDownItemFromObject(value: any): IDropDownItem {

        const item: IDropDownItem = {
            key: '',
            text: '',
            disabled: false,
        };

        if (value.hasOwnProperty('key')) {
            item.key = value.key;
            if (value.hasOwnProperty('value')) {
                item.text = value.value;
            }
            else if (value.hasOwnProperty('text')) {
                item.text = value.text;
            }
            
        } else if (value.hasOwnProperty('name')) {
            item.key = value.name;
            item.text = value.name;
        }

        return item;
    }

    private getDropDownItemFromString(value: string): IDropDownItem {

        const dropDownItem: IDropDownItem = {
            key: value,
            text: value,
            disabled: false
        }

        return dropDownItem;
    }
}