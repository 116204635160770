import 'url-polyfill';
import './custom-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './main/main';
import registerServiceWorker from './registerServiceWorker';
import { IConfig } from './common/AuthenticationModel';
import authentication from './common/Authentication';
import { GlobalConfig } from './GlobalConfig';
import { configureStore } from './store/configureStore';
import {AdvancedError} from "./error/AdvancedError";
import {CLEAR_CACHE_AND_RETRY} from "./error/CustomErrorTypes";
import StandaloneErrorContainer from "./error/StandaloneErrorContainer";

function getGlobalConfiguration(): Promise<any> {
  return fetch('/global-config.json')
    .then(result => {
      return result.json();
    });
}

const store = configureStore();

getGlobalConfiguration()
  .then(result => {
      const config: IConfig = {
      instance: 'https://login.microsoftonline.com/tfp/',
      cacheLocation: "sessionStorage",
      ...result.authConfig
    };
    GlobalConfig.setApiConfig(result.apiConfig);

    const launchApp =  () => {ReactDOM.render(
      <Provider store={store}>
          <App />
      </Provider>
      , document.getElementById('root') as HTMLElement);
    }

    authentication.initialize(config);
    authentication.run(launchApp, launchApp);

    registerServiceWorker();
  })
  .catch (ex => {
      const advancedError = AdvancedError.FromError(CLEAR_CACHE_AND_RETRY,ex);
      ReactDOM.render(
          <StandaloneErrorContainer error={advancedError}/>,
          document.getElementById('root') as HTMLElement
      );
  });


